import React from 'react';
import Img from 'gatsby-image';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { graphql } from 'gatsby';
import LinkedinIcon from '../assets/linkedin-icon.svg';
import ReactMarkdown from 'react-markdown';
import BlogCard from '../components/blog-card';

const Peachie = ({ data }) => {
  const {
    fullName,
    accreditations,
    title,
    intro,
    bodyTitle,
    body,
    photo,
    linkedinUrl,
  } = data.strapiPeachie;

  return (
    <Layout>
      <SEO title="Peachie Thompson" />
      <div>
        <div className="bg-blue-800 bio-angled-bottom z-0">
          <div className="grid pt-16 lg:grid-cols-2 max-w-xl lg:max-w-6xl mx-auto">
            <div className="p-8 sm:pt-40 bio-gradient">
              <h1 className="text-3xl text-blue-50 font-thin">{fullName}</h1>
              <h3 className="text-xl text-blue-200">{accreditations}</h3>
              <h5 className="text-lg text-blue-300 py-6">{title}</h5>
              <p className="text-blue-100 leading-8">
                <ReactMarkdown>{intro}</ReactMarkdown>
              </p>
            </div>
            <div className="lg:pt-20 xl:pt-8">
              <Img
                fluid={photo.childImageSharp.fluid}
                alt="Portrait of Peachie"
              />
              <a href={linkedinUrl}>
                <LinkedinIcon className="fill-current text-gray-100 absolute right-0 mr-8 -mt-24" />
              </a>
            </div>
          </div>
        </div>
        <div className="bg-blue-50">
          <div className="md:border md:border-gray-100 bg-blue-50 md:transform md:-translate-y-24 lg:-translate-y-16 xl:-translate-y-24 mx-auto max-w-xl lg:max-w-2xl md:rounded-md md:shadow-md">
            <h2 className="text-center text-blue-800 pt-10 text-2xl font-bold">
              {bodyTitle}
            </h2>
            <ReactMarkdown className="pt-8 pb-6 px-8 lg:text-lg prose text-blue-800  markdown">
              {body}
            </ReactMarkdown>
          </div>

          <div className="mb-6 -mt-2 w-8 h-1 bg-blue-700 mx-auto" />
        </div>

        <div className="angled-top p-6 pt-16 flex flex-col place-items-center">
          <h5 className="border-b-2 text-blue-100 small-caps pb-4 mb-6 mt-10 text-lg max-w-2xl lg:max-w-7xl w-full">
            Peachie's Recent Articles
          </h5>
          <div className="flex flex-col lg:flex-row space-y-12 lg:space-y-0 lg:space-x-6 max-w-2xl lg:max-w-7xl">
            {data.allStrapiArticle.nodes.map(
              (
                {
                  title,
                  slug,
                  category,
                  author,
                  image,
                  description,
                  publishedAt,
                },
                index
              ) => (
                <div className="flex-1">
                  <BlogCard
                    key={index}
                    title={title}
                    slug={slug}
                    categoryName={category.name}
                    categorySlug={category.slug}
                    authorName={author.name}
                    authorImage={author.picture.childImageSharp.fixed}
                    authorSlug={author.profileSlug}
                    image={image.childImageSharp.fluid}
                    description={description}
                    publishedDate={formatDate(publishedAt)}
                  ></BlogCard>
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

const formatDate = dateString => {
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  return new Date(dateString).toLocaleDateString(undefined, options);
};

export const query = graphql`
  query PeachieQuery {
    strapiPeachie {
      fullName
      accreditations
      title
      intro
      bodyTitle
      body
      linkedinUrl
      photo {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    allStrapiArticle(
      limit: 3
      sort: { order: DESC, fields: publishedAt }
      filter: {
        status: { eq: "published" }
        author: { name: { eq: "Peachie Thompson" } }
      }
    ) {
      nodes {
        title
        author {
          name
          profileSlug
          picture {
            childImageSharp {
              fixed(quality: 90, width: 48, height: 48) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          }
        }
        category {
          name
          slug
        }
        description
        image {
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        publishedAt
        slug
      }
    }
  }
`;

export default Peachie;
